import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { HomeSectionBestReviewResponseDto } from '@/apis/rest/generated/types'
import { StyledDivider } from '@/components/common/divider'
import { FitpetIcon } from '@/components/common/icons/fitpet-icon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { ScrollContainer } from '@/components/domains/etc'
import { Anchor } from '@/components/domains/home/Anchor'
import { SectionCategoryTab } from '@/components/domains/home/new/section-category-tab'
import { THUMBNAIL_SIZES, THUMBNAIL_STATES } from '@/components/domains/products/new-product-card/new-product-card.type'
import { VerticalProductCard } from '@/components/domains/products/new-product-card/vertical-product-card'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useHomeSectionProductTracker } from '@/containers/event-tracker/home-section-product.tracker'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar, textEllipsis } from '@/utils/utilCSS'
import useCustomRouter from '../../../../containers/hooks/useCustomRouter'

const minLength = 3 // 탭내에 최소 상품갯수
const Title = '리뷰 좋은 상품' as const

interface BestReviewListProps {
  homeSectionItem: HomeSectionBestReviewResponseDto
  refCallback: (node: HTMLDivElement) => void
  sectionIndex: number
}

export const HomeSectionBestReview = ({ homeSectionItem, refCallback, sectionIndex }: BestReviewListProps) => {
  if (
    !homeSectionItem?.displayCollectionsWithReviewAndProducts ||
    homeSectionItem.displayCollectionsWithReviewAndProducts.length === 0
  ) {
    return null
  }
  const { push } = useCustomRouter()
  const { color } = useTheme()
  const { triggerCustomEvent } = useTracker()
  const { trackClickHomeSectionProductEvent } = useHomeSectionProductTracker()

  const [selectedId, setSelectedId] = useState<number>(homeSectionItem.displayCollectionsWithReviewAndProducts[0].id)

  // 상품 갯수가 2개 이하일 때 카테고리 탭과 상품을 숨기도록 함
  const categoryTabs = homeSectionItem.displayCollectionsWithReviewAndProducts.filter(
    (category) => category.reviewAndProducts && category.reviewAndProducts.length >= minLength
  )

  const selectedReviewItems = homeSectionItem.displayCollectionsWithReviewAndProducts.find(
    (item) => item.id === selectedId
  )

  const handleTabClick = (id: number) => {
    setSelectedId(id)
  }

  const handleClickProductCard = (product?: ProductTypeDefinedByFrontend) => {
    if (!product) return
    triggerCustomEvent(FitpetMallEventEnum.ClickHomeBestReviewProduct, {
      productId: product.id,
      productName: product.name,
      productReviewAvgScore: product.reviewScore,
    })
    trackClickHomeSectionProductEvent({
      product,
      sectionIndex,
      sectionName: Title,
    })

    push({
      pathname: ROUTES.PRODUCTS.DETAIL,
      query: {
        productId: product.id,
        promotion: Base64ConverterUtil.convertNumberIdToBase64ByKey({
          key: 'ProductPromotionType',
          id: product?.promotion?.id,
        }),
      },
    })
  }

  if (!categoryTabs.length) {
    return null
  }

  return (
    <>
      <Anchor id={Title} refCallback={refCallback} />
      <StyledSeoTitle>
        <Typo variant={TypoVariant.Heading5}>{Title}</Typo>
      </StyledSeoTitle>
      <SectionCategoryTab categoryTabs={categoryTabs} selectedId={selectedId} onClick={handleTabClick} />
      <ScrollContainer id={selectedId.toString()}>
        <StyledProductCardList>
          {selectedReviewItems?.reviewAndProducts?.map((item) => {
            const productData = transformProductTypeDefinedByFrontend(item.product)
            return (
              <div key={productData.id} style={{ position: 'relative' }}>
                <div style={{ width: '20rem', flexShrink: 0 }}>
                  <VerticalProductCard size={THUMBNAIL_SIZES.half} onClick={() => handleClickProductCard(productData)}>
                    <VerticalProductCard.Thumbnail
                      productId={productData.id}
                      productName={productData.name}
                      productPromotionScheme={productData.promotion}
                      thumbnailState={THUMBNAIL_STATES.basicLabel}
                      imageUrl={productData.thumbnail}
                      isLiked={productData.isLiked}
                      isRealSoldOut={productData.isRealSoldOut}
                      isNotAvailable={productData.isNotAvailable}
                      trackingData={productData.thumbnailData}
                      sticker={productData.sticker}
                    />
                    <StyledReviewScore>
                      <FitpetIcon type="ic-star" color={color.yellow500} size="1.6rem" />
                      <Typo variant={TypoVariant.Body1Bold} color={color.gray700}>
                        {productData.reviewScore}
                      </Typo>
                    </StyledReviewScore>
                    <StyledProductName>{productData.name}</StyledProductName>
                    <StyledReviewText>{item.reviewBody}</StyledReviewText>
                  </VerticalProductCard>
                </div>
              </div>
            )
          })}
        </StyledProductCardList>
      </ScrollContainer>
      <StyledDivider height="0.1rem" backgroundColor={color.gray70} margin="2.4rem 0 0" />
    </>
  )
}

// 디자인 시스템의 Title Component 사용하지 않음 (reboot 에서 진행할 예정)
const StyledSeoTitle = styled.div`
  padding: 0 ${({ theme: { spacing } }) => spacing.size14};
  width: ${({ theme: { spacing } }) => spacing.per100};
  text-align: left;
  margin: ${({ theme: { spacing } }) => spacing.size24} 0 0 0;
`

const StyledProductCardList = styled.div`
  ${removeScrollbar};
  display: flex;
  column-gap: ${({ theme: { spacing } }) => spacing.size16};

  & > *:first-child {
    margin-left: ${({ theme: { spacing } }) => spacing.size16};
  }

  & > *:last-child {
    padding-right: ${({ theme: { spacing } }) => spacing.size16};
  }
`

const StyledProductName = styled.div`
  pointer-events: none;
  margin-top: 0.8rem;
  color: ${(props) => props.theme.color.gray900};
  font-size: ${(props) => props.theme.fontSize.body1};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 1.8rem;
  letter-spacing: ${({ theme }) => theme.letterSpacing.font};
  ${textEllipsis(1)};
`

const StyledReviewText = styled.div`
  pointer-events: none;
  margin-top: 0.8rem;
  color: ${(props) => props.theme.color.gray900};
  font-size: ${(props) => props.theme.fontSize.body3};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  line-height: 1.8rem;
  letter-spacing: ${({ theme }) => theme.letterSpacing.font};
  ${textEllipsis(4)};
`

const StyledReviewScore = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  line-height: 1.8rem;
  margin-top: 0.8rem;
`
