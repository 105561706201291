import { useEffect } from 'react'
import styled from 'styled-components'
import { HomeSectionList } from '@/components/domains/home/new/home-section-list/home-section-list'
import { HomeNavigationBar } from '@/components/domains/layouts/header'
import SeoHeader from '@/components/domains/layouts/header/SeoHeader'
import { HomeHeader } from '@/components/domains/layouts/header/home-header'
import { Z_INDEXES } from '@/constants/legacy/constLayout'
import { useCustomRouter, usePetType } from '@/containers/hooks'
import { AdPetType } from '@/graphql/generated/schemas'
import type { NextPage } from 'next'

const Home: NextPage = () => {
  const { getPetType, setPetType } = usePetType()
  const { query, replace, pathname, isReady } = useCustomRouter()
  const petTypeQuery = query.petType as AdPetType
  const petType = getPetType()

  useEffect(() => {
    if (!isReady) {
      return
    }
    const petTypes = Object.values(AdPetType)
    const params = new URLSearchParams(query as unknown as string)
    if (petTypeQuery && petTypes.includes(petTypeQuery)) {
      setPetType(petTypeQuery)
    }
    params.delete('petType')
    replace({ pathname, query: params.toString() })
  }, [petTypeQuery, isReady])

  return (
    <div key={petType}>
      <SeoHeader
        title="건강한 반려생활의 시작 - 핏펫"
        metaDescription="반려동물 사료, 간식, 용품부터 동물병원 예약까지 한 번에! 건강한 반려생활의 시작, 핏펫이 함께 합니다."
        pathname=""
      />
      <StyledHeaderWrapper>
        <HomeHeader />
        <HomeNavigationBar />
      </StyledHeaderWrapper>
      <HomeSectionList />
    </div>
  )
}

export default Home

const StyledHeaderWrapper = styled.div`
  z-index: ${Z_INDEXES.HEADER};
  position: sticky;
  top: 0;
`
